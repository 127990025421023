/**
 * Created by zhaoyue on 17/2/17.
 */

import { app as api } from '@ekuaibao/whispered'
import { getMeAuthState } from './auth-check-action'
import { getV } from '@ekuaibao/lib/lib/help'

export default [
  {
    path: '/auth-check',
    exact: true,
    onload: () => {
      return import('./auth-check-view')
    },
    onbefore: async app => {
      await app.apply('@auth-check', 'check:auth')
    }
  },
  {
    id: '@auth-check',
    reducer: () => import('./auth-check-reducer'),
    onbefore: async (app, next) => {
      await api.dataLoader('@common.userinfo').load()
      await app.apply('@auth-check', 'check:auth', false)
      next()
    },
    'check:auth': async () => {
      if (window.__PLANTFORM__ === 'KD_CLOUD') {
        return
      }
      try {
        const meInfo = await getMeAuthState()
        const isAuthorized = getV(meInfo, 'value.isAuthorized', '')
        const hash = getV(location, 'hash', '')
        const isAuthPage = hash.includes('/auth-check')

        if (isAuthorized) {
          isAuthPage && api.go('/bills')
        } else {
          //FetchConfig有错误的handle，这里在跳转容易来回跳
          await api.dataLoader('@common.userinfo').load({ type: 'check' })
          await api.dataLoader('@common.powers').load() // 拉取最新的 powders
          // !isAuthPage && api.go('/auth-check')
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
]
