/**
 * Created by zhaoyue on 17/2/17.
 */

export const ID = '@auth-check'

export default {
  ID: ID,
  GET_POWERS_DETAIL: `${ID}/GET_POWERS_DETAIL`,
  GET_POWERS_STAFFS: `${ID}/GET_POWERS_STAFFS`,
  PUT_POWERS_STAFFS: `${ID}/PUT_POWERS_STAFFS`,
  VERIFIVATION_CAPTCHACODE: `${ID}/VERIFIVATION_CAPTCHACODE`,
  GET_Auth_STAFFS: `${ID}/GET_Auth_STAFFS`
}
