/**
 * Created by zhaoyue on 17/2/17.
 */

import key from './key'

import { Resource } from '@ekuaibao/fetch'
const staffs = new Resource('/api/v1/organization/staffs')
const powers = new Resource('/api/charge/powers')
const initialization = new Resource('/api/v1/initialization')

//获取功能详情 /api/charge/powers/detail/${powerCode}
export function getPowersDetail(powerCode, done) {
  return {
    type: key.GET_POWERS_DETAIL,
    payload: powers.GET('/detail/$powerCode', { powerCode }),
    done
  }
}

//获取某企业的某功能已授权的员工id集合 /api/charge/powers/staff
export function getPowersStaff(params, done) {
  return {
    type: key.GET_POWERS_STAFFS,
    payload: powers.GET('/staff', params),
    done
  }
}

//员工授权 /api/charge/powers/staff
export function putPowersStaff(params, done) {
  return {
    type: key.PUT_POWERS_STAFFS,
    payload: powers.PUT('/staff', { ...params }),
    done
  }
}
//获取有人员激活权限的员工信息
export function getAuthStaff(done) {
  return {
    type: key.GET_Auth_STAFFS,
    payload: staffs.GET('/byPermission/activate'),
    done
  }
}
export function getMeAuthState() {
  return staffs.GET('/me')
}

export function verifivavtionCode(param) {
  return {
    type: key.VERIFIVATION_CAPTCHACODE,
    payload: initialization.POST('/initialize', param)
  }
}
